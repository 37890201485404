<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-container fluid>
      <v-row>
        <!-- <v-container class="d-flex"> -->
          <div v-if="flashMessage">
            <v-alert 
            type="success"
            class="flash-message"
            >{{flashMessage}}</v-alert>
          </div>
          <v-col v-for="(item, i) in items" :key="'col_' + i" cols="12" md="3">
            <v-card class="mx-auto" elevation="8">
              <v-list shaped dense>
                <v-list-group :value="false" :prepend-icon="item.icon">
                  <template v-slot:activator>
                    <v-list-item-title>{{ item.nama }}</v-list-item-title>
                  </template>

                  <v-list-item-group :value="false" no-action sub-group v-for="(subItem, k) in item.sub_menu" :key="'submenu_' + k">
                    <!-- SUBMENU ITEM -->
                    <v-list-item :key="'subItem_' + k" @click="handleSubItemClick(subItem)" :to="subItem.route">
                      <v-list-item-icon>
                        <v-icon color="primary" style="font-size: 10px;">{{ subItem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ subItem.text }}
                      </v-list-item-title>
                      <v-list-item-action v-if="subItem.submenu && subItem.submenu.length">
                        <!-- <v-icon @click.stop="toggleSubmenu(subItem)">mdi-chevron-right</v-icon> -->
                        <v-icon @click.stop="toggleSubmenu(subItem)" :class="{ 'rotate-90': subItem.submenuOpen }">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <!-- SUBSUBMENU ITEM -->
                    <v-list-item-group :value="false" no-action sub-group v-if="subItem.submenuOpen" :key="'submenu_' + k" class="submenu-group2">
                      <v-list-item v-for="(subSubItem, l) in subItem.submenu" :key="l" :to="subSubItem.route">
                        <v-list-item-icon>
                          <v-icon color="primary" style="font-size: 10px;">mdi-checkbox-blank-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ subSubItem.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </v-card>
          </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import { appendQueryString } from "@/services/checkApiParams.js";
  import axios from 'axios';
  // import { getApiUrl } from '@/config/configService.js';
  // import { link } from "@/config/link";
  // import { settings } from "@/config/settings";
  export default {
    data: () => ({
      items: [],
      accessToken : '',
      itemCompany: [],
      flashMessage: null,
      }),
    name: 'App',
    created() {
      this.user_id = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      if (token) {
        this.accessToken = token;
        // console.log('Access Token:', this.accessToken);
      } else {
        console.error('Token not found.');
      }
    },
    computed: {
      theme() {
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      }
    },
    methods: {
      handleItemClick(item) {
        item.submenuOpen = !item.submenuOpen;
      },
      handleSubItemClick(subItem) {
        subItem.submenuOpen = !subItem.submenuOpen;
      },
      toggleSubmenu(item) {
        item.submenuOpen = !item.submenuOpen;
        // console.log(item.submenuOpen)
      },
      logout() {
        localStorage.removeItem("token");
        this.$router.push("/login");
        this.flashMessage = "Session expired. Please log in again.";
        setTimeout(() => {
          this.flashMessage = "";
        }, 5000);
      },
      async leftMenu() {
        try {

          // const id_user = this.id;
          // console.log("cek Id user", id_user)

          // const response = await link.get(`/user/left-menu&id_user=${this.id}`);
          const apiUrl = process.env.VUE_APP_API_URL;
          const baseUrl = `${apiUrl}/user/left-menu`;
          const params = { id_user : this.user_id};
          const newUrl = appendQueryString(baseUrl, params);
          // console.log("yaa", newUrl)
          const response = await axios.get(newUrl,{
            headers : {
              Authorization : `Bearer ${this.accessToken}`
            }
          });
          this.items = this.convertData(response.data.data);
          this.items.splice(0, 1);
          // console.log("cek item",this.items);
        } catch (error) {
          // console.log(error);
          if (error.response && error.response.status === 401) {
            console.error("Token expired or invalid. Logging out...");
            this.logout();
          }
        }
      },
      convertData(data) {
        return data.map(item => ({
          nama: item.nama,
          icon: item.icon,
          route: item.action,
          submenuOpen: false,
          sub_menu: item.sub_menu ? item.sub_menu.map(subItem => ({
            text: subItem.nama,
            // icon: subItem.icon,
            route: subItem.action,
            submenuOpen: false,
            submenu: subItem.sub_menu ? subItem.sub_menu.map(subSubItem => ({
              text: subSubItem.nama,
              icon: subSubItem.icon,
              route: subSubItem.action,
              submenuOpen: false
            })) : []
          })) : []
        }));
      },
      async fetchData() {
        try {
          const apiUrl = process.env.VUE_APP_API_URL;
          const baseUrl = `${apiUrl}/pengaturanumum/profile-perusahaan`;
          const newUrl = appendQueryString(baseUrl);
          const response = await axios.get(newUrl,{
            headers : {
              Authorization : `Bearer ${this.accessToken}`
            }
          });
          this.itemCompany = response.data;
          const nama = this.itemCompany.nama;
          localStorage.setItem('nCompany', nama);
          const company = this.itemCompany;
          localStorage.setItem('company', JSON.stringify(company));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    },
    mounted() {
      this.leftMenu();
      this.fetchData();
      this.userlogin = localStorage.getItem('username');
      this.id = localStorage.getItem('id');
      const message = localStorage.getItem('flashMessage');
      const justLoggedIn = localStorage.getItem('justLoggedIn');
      if (justLoggedIn) {
        setTimeout(() => {
          this.flashMessage = message;
        }, 500);
        setTimeout(() => {
          this.flashMessage = null;
          localStorage.removeItem('justLoggedIn');
        }, 3000);
      }
    }
  };
</script>

<style scoped>
  .flash-message {
    height: 50px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    animation: slideInRight 0.5s ease-in-out forwards,
    fadeOut 5s ease-in-out forwards;
  }
  .rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }
  @keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
  /* .router-link {
    font-size: 12px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  .router-link:hover {
    text-decoration: none;
    color: inherit;
  } */
</style>
