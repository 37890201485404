import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/LoginApp.vue";
import VueMeta from "vue-meta";
import NotFound from "@/views/NotFound.vue";
// import { component } from "vue/types/umd";


Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // Route ALl Laporan
  {
    path: "/laporan/laporan-a",
    name: "laporanA",
    component: () => import("../views/laporan/laporanA.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/laporan-b",
    name: "laporanB",
    component: () => import("../views/laporan/laporanB.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/posisi-wip",
    name: "posisi-wip",
    component: () => import("../views/laporan/posisiWip.vue"),
    meta: { requiresAuth: true, title: "Posisi WIP | SINTACT V2" },
  },
  {
    path: "/laporan/laporan-mut-bb",
    name: "LaporanMutBb",
    component: () => import("../views/laporan/laporanMutBb.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/laporan-mut-fg",
    name: "LaporanMutFg",
    component: () => import("../views/laporan/laporanMutFg.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/laporan-mut-eq",
    name: "LaporanMutEq",
    component: () => import("../views/laporan/laporanMutEq.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/laporan-mut-scrap",
    name: "LaporanMutScrap",
    component: () => import("../views/laporan/laporanMutScrap.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/log-act",
    name: "LogAct",
    component: () => import("../views/laporan/logAct.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2" },
  },
  {
    path: "/laporan/business",
    name: "laporanBusiness",
    component: () => import("../views/laporan/grafikBusiness.vue"),
    meta: { requiresAuth: true, title: "Laporan Business | SINTACT V2" },
  },
  // END route Laporan
  {
    path: "/sales-order/index",
    name: "sales-order",
    component: () => import("../views/penjualan/sales-order/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pesanan | SINTACT V2" },
  },
  {
    path: "/sales-order/view/:Id",
    name: "SoView",
    component: () => import("../views/penjualan/sales-order/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Pesanan | SINTACT V2" },
  },
  {
    path: "/sales-order/update/:Id",
    name: "so-update",
    component: () => import("../views/penjualan/sales-order/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Sales Order | SINTACT V2" },
  },
  {
    path: "/so-invoice/index",
    name: "so-invoice",
    component: () => import("../views/penjualan/so-invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Penjualan | SINTACT V2" },
  },
  {
    path: "/so-invoice/view/:Id",
    name: "so-invoice-view",
    component: () => import("../views/penjualan/so-invoice/viewApp.vue"),
    meta: { requiresAuth: true,title: "Detail Tagihan Penjualan | SINTACT V2",},
  },
  {
    path: "/so-invoice/update/:Id",
    name: "so-invoice-update",
    component: () => import("../views/penjualan/so-invoice/updateApp.vue"),
    meta: { requiresAuth: true,title: "Detail Tagihan Penjualan | SINTACT V2",},
  },
  {
    path: "/packing-list/index",
    name: "packing-list",
    component: () => import("../views/penjualan/packing-list/indexApp.vue"),
    meta: { requiresAuth: true, title: "Packing List | SINTACT V2" },
  },
  {
    path: "/packing-list/update/:Id",
    name: "packing-list-update",
    component: () => import("../views/penjualan/packing-list/updateApp.vue"),
    meta: { requiresAuth: true,title: "Packing List | SINTACT V2",},
  },
  {
    path: "/packing-list/view/:Id",
    name: "packing-list-view",
    component: () => import("../views/penjualan/packing-list/viewApp.vue"),
    meta: { requiresAuth: true,title: "Packing List | SINTACT V2",},
  },
  {
    path: "/pelanggan/index-pelanggan",
    name: "Pelanggan",
    component: () => import("../views/pelanggan/indexPelanggan.vue"),
    meta: { requiresAuth: true, title: "Pelanggan | SINTACT V2" },
  },
  {
    path: "/pelanggan/view-pelanggan/:Id",
    name: "PelView",
    component: () => import("../views/pelanggan/viewPelanggan.vue"),
    meta: { requiresAuth: true, title: "Detail Pelanggan | SINTACT V2" },
  },
  {
    path: "/pelanggan/index-pemasok",
    name: "Pemasok",
    component: () => import("../views/pelanggan/indexPemasok.vue"),
    meta: { requiresAuth: true, title: "Pemasok | SINTACT V2" },
  },
  {
    path: "/pelanggan/view-pemasok/:Id",
    name: "SuppView",
    component: () => import("../views/pelanggan/viewPemasok.vue"),
    meta: { requiresAuth: true, title: "Detail Pemasok | SINTACT V2" },
  },
  {
    path: "/man-bom-mst/index",
    name: "man-bom-mst",
    component: () => import("../views/ppic/man-bom-mst/indexApp.vue"),
    meta: { requiresAuth: true, title: "Bill Of Material | SINTACT V2" },
  },
  {
    path: "/man-bom-mst/view/:Id",
    name: "viewApp",
    component: () => import("../views/ppic/man-bom-mst/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Bill Of Material | SINTACT V2" },
  },
  {
    path: "/man-bom-mst/update/:Id",
    name: "updateApp",
    component: () => import("../views/ppic/man-bom-mst/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Bill Of Material | SINTACT V2" },
  },
  {
    path: "/man-jo-mst/index",
    name: "man-jo-mst",
    component: () => import("../views/ppic/man-jo-mst/indexApp.vue"),
    meta: { requiresAuth: true, title: "Job Order | SINTACT V2" },
  },
  {
    path: "/man-jo-mst/view/:Id",
    name: "JoView",
    component: () => import("../views/ppic/man-jo-mst/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Job Order | SINTACT V2" },
  },
  {
    path: "/man-jo-mst/update/:Id",
    name: "JoUpdate",
    component: () => import("../views/ppic/man-jo-mst/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Job Order | SINTACT V2" },
  },
  {
    path: "/surat-jalan/index",
    name: "surat-jalan",
    component: () => import("../views/ppic/surat-jalan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Surat Jalan | SINTACT V2" },
  },
  {
    path: "/surat-jalan/view/:Id",
    name: "SjView",
    component: () => import("../views/ppic/surat-jalan/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Surat Jalan | SINTACT V2" },
  },
  {
    path: "/surat-jalan/update/:Id",
    name: "SjUpdate",
    component: () => import("../views/ppic/surat-jalan/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Surat Jalan | SINTACT V2" },
  },
  {
    path: "/purchase-order/index",
    name: "purchase-order",
    component: () => import("../views/purchase-order/indexApp.vue"),
    meta: { requiresAuth: true, title: "Purchase Order | SINTACT V2" },
  },
  {
    path: "/purchase-order/view/:Id",
    name: "po-view",
    component: () => import("../views/purchase-order/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Purchase Order | SINTACT V2" },
  },
  {
    path: "/purchase-order/update/:Id",
    name: "po-update",
    component: () => import("../views/purchase-order/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Purchase Order | SINTACT V2" },
  },
  {
    path: "/barang/index-barang",
    name: "barang",
    component: () => import("../views/inventory/barang/indexApp.vue"),
    meta: { requiresAuth: true, title: "Barang | SINTACT V2" },
  },
  {
    path: "/barang/view/:Id",
    name: "BarangView",
    component: () => import("../views/inventory/barang/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Barang | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/index-material",
    name: "penerimaan-bb",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/indexMaterial.vue"),
    meta: { requiresAuth: true, title: "Material In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/view-material/:Id",
    name: "View-material-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/viewMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/update-material/:Id",
    name: "update-material-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/updateMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material In | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/index-material",
    name: "pengeluaran-bb",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/indexMaterial.vue"),
    meta: { requiresAuth: true, title: "Material Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/view-material/:Id",
    name: "View-material-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/viewMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/update-material/:Id",
    name: "update-material-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/updateMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material Out | SINTACT V2" },
  },
  {
    path: "/wip-in/index",
    name: "penerimaan-wip-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-wip/indexWip.vue"),
    meta: { requiresAuth: true, title: "WIP IN | SINTACT V2" },
  },
  {
    path: "/wip-in/view/:Id",
    name: "wipView",
    component: () => import("../views/inventory/penerimaan/penerimaan-wip/viewWip.vue"),
    meta: { requiresAuth: true, title: "Detail WIP In | SINTACT V2" },
  },
  {
    path: "/wip-out/index",
    name: "penerimaan-wip-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wip/indexWip.vue"),
    meta: { requiresAuth: true, title: "WIP Out | SINTACT V2" },
  },
  {
    path: "/wip-out/view/:Id",
    name: "View-wip-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wip/viewWip.vue"),
    meta: { requiresAuth: true, title: "Detail WIP Out | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/index-fg",
    name: "index-fg-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/indexFg.vue"),
    meta: { requiresAuth: true, title: "Finish GOOD In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/view-fg/:Id",
    name: "View-fg-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/viewFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/update-fg/:Id",
    name: "Update-fg-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/updateFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD In | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/index-fg",
    name: "index-fg-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/indexFg.vue"),
    meta: { requiresAuth: true, title: "Finish GOOD Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/view-fg/:Id",
    name: "View-fg-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/viewFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/update-fg/:Id",
    name: "Update-fg-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/updateFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD Out | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/index-waste",
    name: "penerimaan-wst",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/indexWaste.vue"),
    meta: { requiresAuth: true, title: "Waste In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/view-waste/:Id",
    name: "View-wst-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/viewWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/update-waste/:Id",
    name: "Update-wst-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/updateWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste In | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/index-waste",
    name: "index-wst-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/indexWaste.vue"),
    meta: { requiresAuth: true, title: "Waste Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/view-waste/:Id",
    name: "View-wst-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/viewWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/update-waste/:Id",
    name: "Update-wst-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/updateWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste Out | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/index-mesin",
    name: "penerimaan-mesin",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/indexMesin.vue"),
    meta: { requiresAuth: true, title: "Equipment In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/view-mesin/:Id",
    name: "View-mesin-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/viewMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment In | SINTACT V2" },
  },
  {
    path: "/penerimaan-barang/update-mesin/:Id",
    name: "update-mesin-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/updateMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment In | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/index-mesin",
    name: "index-mesin-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/indexMesin.vue"),
    meta: { requiresAuth: true, title: "Equipment Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/view-mesin/:Id",
    name: "View-mesin-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/viewMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment Out | SINTACT V2" },
  },
  {
    path: "/pengeluaran-barang/update-mesin/:Id",
    name: "Update-mesin-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/updateMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment Out | SINTACT V2" },
  },
  {
    path: "/hutang/index-po-invoice",
    name: "index-po-invoice",
    component: () => import("../views/hutang/po-invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2" },
  },
  {
    path: "/hutang/po-invoice/view/:Id",
    name: "view-po-invoice",
    component: () => import("../views/hutang/po-invoice/viewApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2" },
  },
  {
    path: "/hutang/po-invoice/update/:Id",
    name: "update-po-invoice",
    component: () => import("../views/hutang/po-invoice/updateApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2" },
  },
  {
    path: "/hutang/index-hut-invoice",
    name: "index-hut-invoice",
    component: () => import("../views/hutang/daftar-hutang/indexApp.vue"),
    meta: { requiresAuth: true, title: "Daftar Hutang | SINTACT V2" },
  },
  {
    path: "/hutang/index-hut-bayar",
    name: "index-hut-bayar",
    component: () => import("../views/hutang/pembayaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2" },
  },
  {
    path: "/hutang/hut-bayar/view/:Id",
    name: "view-hut-bayar",
    component: () => import("../views/hutang/pembayaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2" },
  },
  {
    path: "/hutang/hut-bayar/update/:Id",
    name: "update-hut-bayar",
    component: () => import("../views/hutang/pembayaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2" },
  },
  {
    path: "/piutang/index-invoice",
    name: "piutang-index-invoice",
    component: () => import("../views/piutang/invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2" },
  },
  {
    path: "/piutang/update-invoice/:Id",
    name: "update-invoice",
    component: () => import("../views/piutang/invoice/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2" },
  },
  {
    path: "/piutang/index-pembayaran",
    name: "piutang-index-bayar",
    component: () => import("../views/piutang/pembayaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2" },
  },
  {
    path: "/piutang/view-pembayaran/:Id",
    name: "view-bayar",
    component: () => import("../views/piutang/pembayaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2" },
  },
  {
    path: "/piutang/update-pembayaran/:Id",
    name: "update-bayar",
    component: () => import("../views/piutang/pembayaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2" },
  },
  {
    path: "/harta/index-kategori/",
    name: "harta-index-kategori",
    component: () => import("../views/harta/indexKategori.vue"),
    meta: { requiresAuth: true, title: "Kategori Aset | SINTACT V2" },
  },
  {
    path: "/harta/index-bangunan/",
    name: "index-bangunan",
    component: () => import("../views/harta/indexBangunan.vue"),
    meta: { requiresAuth: true, title: "Bangunan  | SINTACT V2" },
  },
  {
    path: "/harta/index-nonbangunan/",
    name: "index-nonbangunan",
    component: () => import("../views/harta/indexNonBangunan.vue"),
    meta: { requiresAuth: true, title: "Non Bangunan  | SINTACT V2" },
  },
  {
    path: "/harta/index-assets-penyusutan/",
    name: "index-penyusutan",
    component: () => import("../views/harta/penyusutan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Assets Penyusutan | SINTACT V2" },
  },
  {
    path: "/harta/view-penyusutan/:Id",
    name: "view-penyusutan",
    component: () => import("../views/harta/penyusutan/viewApp.vue"),
    meta: { requiresAuth: true, title: "Assets Penyusutan | SINTACT V2" },
  },
  {
    path: "/kas-bank/index/",
    name: "kas-bank-index",
    component: () => import("../views/kas-bank/pengaturan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Kas & Bank | SINTACT V2" },
  },
  {
    path: "/atur-saldo/index/",
    name: "atur-saldo-index",
    component: () => import("../views/kas-bank/pengaturan/aturSaldo.vue"),
    meta: { requiresAuth: true, title: "Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/view/:Id",
    name: "kas-bank-view",
    component: () => import("../views/kas-bank/pengaturan/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-kartu/",
    name: "kas-bank-index-kartu",
    component: () => import("../views/kas-bank/pengaturan/indexKartu.vue"),
    meta: { requiresAuth: true, title: "Kartu | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-mata-uang/",
    name: "kas-bank-index-mata-uang",
    component: () => import("../views/kas-bank/pengaturan/indexMataUang.vue"),
    meta: { requiresAuth: true, title: "Mata Uang | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-penerimaan",
    name: "kas-bank-index-terima",
    component: () => import("../views/kas-bank/penerimaan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Penerimaan Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/view-terima/:Id",
    name: "kas-bank-view-terima",
    component: () => import("../views/kas-bank/penerimaan/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Penerimaan Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/update-terima/:Id",
    name: "kas-bank-update-terima",
    component: () => import("../views/kas-bank/penerimaan/updateApp.vue"),
    meta: { requiresAuth: true, title: "Update Penerimaan Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-pengeluaran",
    name: "kas-bank-index-keluar",
    component: () => import("../views/kas-bank/pengeluaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pengeluaran Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/view-keluar/:Id",
    name: "kas-bank-view-keluar",
    component: () => import("../views/kas-bank/pengeluaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Pengeluaran Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/update-keluar/:Id",
    name: "kas-bank-update-keluar",
    component: () => import("../views/kas-bank/pengeluaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Update Pengeluaran Kas & Bank | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-laporan-kas-harian",
    name: "kas-bank-index-laporan-kas-harian",
    component: () => import("../views/kas-bank/laporan/indexKasHarian.vue"),
    meta: { requiresAuth: true, title: "Laporan Kas Harian | SINTACT V2" },
  },
  {
    path: "/kas-bank/index-laporan-cash-flow",
    name: "kas-bank-index-laporan-cash-flow",
    component: () => import("../views/kas-bank/laporan/indexCashFlow.vue"),
    meta: { requiresAuth: true, title: "Laporan Cash Flow | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-kode-akun",
    name: "index-kode-akun",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/indexAkun.vue"),
    meta: { requiresAuth: true, title: "Kode Akun | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-atur-tipe-akun",
    name: "atur-tipe",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexTipe.vue"),
    meta: { requiresAuth: true, title: "Atur Tipe Akun | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-atur-kelompok-akun",
    name: "atur-kelompok",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexKelompok.vue"),
    meta: { requiresAuth: true, title: "Atur Kelompok Akun | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-saldo-akun",
    name: "index-saldo",
    component: () => import("../views/pembukuan/atur-akun/saldo-akun/indexSaldo.vue"),
    meta: { requiresAuth: true, title: "Saldo Akun | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-atur-hpp",
    name: "atur-hpp",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexHpp.vue"),
    meta: { requiresAuth: true, title: "Atur Kelompok Akun | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-hpp",
    name: "index-hpp",
    component: () => import("../views/pembukuan/hpp/indexApp.vue"),
    meta: { requiresAuth: true, title: "HPP | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-link",
    name: "index-link",
    component: () => import("../views/pembukuan/atur-akun/akun-link/indexApp.vue"),
    meta: { requiresAuth: true, title: "ACC Link | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-neraca-percobaan",
    name: "index-neraca-percobaan",
    component: () => import("../views/pembukuan/neraca-percobaan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Neraca Percobaan | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-neraca",
    name: "index-neraca",
    component: () => import("../views/pembukuan/neraca/indexApp.vue"),
    meta: { requiresAuth: true, title: "Neraca | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-laporan-rugi-laba",
    name: "index-laporan-rugi-laba",
    component: () =>
      import("../views/pembukuan/laporan-rugi-laba/indexApp.vue"),
    meta: { requiresAuth: true, title: "Laporan Rugi Laba | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-jurnal",
    name: "index-jurnal",
    component: () => import("../views/pembukuan/jurnal/indexApp.vue"),
    meta: { requiresAuth: true, title: "Jurnal | SINTACT V2" },
  },
  {
    path: "/pembukuan/view-jurnal/:Id",
    name: "view-jurnal",
    component: () => import("../views/pembukuan/jurnal/viewApp.vue"),
    meta: { requiresAuth: true, title: "Jurnal | SINTACT V2" },
  },
  {
    path: "/pembukuan/index-buku-besar",
    name: "index-buku-besar",
    component: () => import("../views/pembukuan/buku-besar/indexApp.vue"),
    meta: { requiresAuth: true, title: "Buku Besar | SINTACT V2" },
  },
  {
    path: "/ceisa/dokumen-aju/",
    name : "ceisa-dokumen-aju",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/indexApp.vue"),
  },
  // {
  //   path: "/ceisa/view-dokumen-aju/view/:Id",
  //   name : "ceisa-view-dokumen-aju",
  //   component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewApp.vue"),
  // },
  {
    path: "/ceisa/view-dokumen-aju/viewBc20/:Id",
    name : "ceisa-view-bc-20",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc20.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc23/:Id",
    name : "ceisa-view-bc-23",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc23.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc25/:Id",
    name : "ceisa-view-bc-25",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc25.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc27/:Id",
    name : "ceisa-view-bc-27",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc27.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc261/:Id",
    name : "ceisa-view-bc-261",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc261.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc262/:Id",
    name : "ceisa-view-bc-262",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc262.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc30/:Id",
    name : "ceisa-view-bc-30",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc30.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc40/:Id",
    name : "ceisa-view-bc-40",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc40.vue"),
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc41/:Id",
    name : "ceisa-view-bc-41",
    component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc41.vue"),
  },
  // {
  //   path: "/pengaturan-umum/index-company/",
  //   name : "company",
  //   component: () => 
  //     import("../views/pengaturan-umum/company/indexCompany.vue"),
  //     meta: { requiresAuth: true, title: "Profile Perusahaan | SINTACT V2" },
  // },
  {
    path: "/pengaturan-umum/index-user/",
    name : "index-user",
    component: () => 
      import("../views/pengaturan-umum/user/indexUser.vue"),
      meta: { requiresAuth: true, title: "Users | SINTACT V2" },
  },
  {
    path: "/pengaturan-umum/view-user/:Id",
    name : "view-user",
    component: () => 
      import("../views/pengaturan-umum/user/viewApp.vue"),
      meta: { requiresAuth: true, title: "Detail User | SINTACT V2" },
  },
  {
    path: "/pengaturan-umum/index-profil-pengguna/",
    name : "index-Profil",
    component: () => 
      import("../views/pengaturan-umum/profil/indexProfil.vue"),
      meta: { requiresAuth: true, title: "User Profile | SINTACT V2" },
  },
  {
    path: "/pengaturan-umum/index-reff-tarif/",
    name : "index-Tarif",
    component: () => import("../views/pengaturan-umum/akuntansi/indexReffTarif.vue"),
  },
  {
    path: "/pengaturan-umum/index-harta/",
    name : "index-set-harta",
    component: () => import("../views/pengaturan-umum/akuntansi/indexHarta.vue"),
  },
  {
    path: "/pengaturan-umum/index-kategori/",
    name : "index-set-kategori",
    component: () => import("../views/pengaturan-umum/inventory/indexKategori.vue"),
  },
  {
    path: "/pengaturan-umum/index-transaksi/",
    name : "index-set-Transaksi",
    component: () => import("../views/pengaturan-umum/inventory/indexTransaksi.vue"),
  },
  {
    path: "/pengaturan-umum/index-company/",
    name : "index-companay",
    component: () => 
      import("../views/pengaturan-umum/profil/indexCompany.vue"),
      meta: { requiresAuth: true, title: "Company Profile | SINTACT V2" },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

/// Navigation Guard
router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "SINTACT V2";
  });
  // Cek apakah rute membutuhkan autentikasi
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !localStorage.getItem("token")
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
