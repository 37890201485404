<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="navbar">
      <v-list>
        <template v-for="(item, i) in items">
          <!-- MENU ITEM -->
          <v-list-item :key="'item_' + i" @click="handleItemClick(item)" :class="{ 'submenu-open': item.submenuOpen }">
            <v-list-item-icon>
              <v-icon color="white" style="font-size: 15px">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.nama }}
              <!-- <router-link :to="item.route" v-if="item.route">{{ item.nama }}</router-link>
              <span v-else>{{ item.nama }}</span> -->
            </v-list-item-title>
            <v-list-item-action v-if="item.sub_menu">
              <v-icon @click.stop="toggleSubmenu(item)" :class="{ 'rotate-90': item.submenuOpen }">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <!-- SUBMENU ITEM -->
          <v-list-item-group v-if="item.submenuOpen" :key="'submenu_group_' + i" class="submenu-group">
            <template v-for="(subItem, j) in item.sub_menu">
              <v-list-item :key="'subItem_' + j" @click="handleSubItemClick(subItem)">
                <v-list-item-icon>
                  <v-icon color="white" style="font-size: 10px;">{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title >
                  {{ subItem.text }}
                  <!-- <router-link :to="subItem.route" v-if="subItem.route">{{ subItem.text }}</router-link>
                  <span v-else>{{ subItem.text }}</span> -->
                </v-list-item-title>
                <v-list-item-action v-if="subItem.submenu && subItem.submenu.length">
                <v-icon @click.stop="toggleSubmenu(subItem)" :class="{ 'rotate-90': subItem.submenuOpen }">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <!-- SUBSUBMENU ITEM -->
              <v-list-item-group v-if="subItem.submenuOpen" :key="'submenu_' + i + '_' + j" class="submenu-group2">
                <v-list-item v-for="(subSubItem, k) in subItem.submenu" :key="k" @click="navigateAndClose(subSubItem)">
                  <v-list-item-icon>
                    <v-icon color="white" style="font-size: 10px;">mdi-checkbox-blank-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subSubItem.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </template>
          </v-list-item-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#ffd777">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text" @click="$router.push(home[0].route)" style="cursor: pointer;">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon style="margin-right: 3px;">
        mdi-alarm
      </v-icon>
      <v-chip
        outlined
      >{{ this.formatTimer() }}</v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn 
          text 
          icon 
          @click="logout" 
          color="#424a5d"
          v-on="{ ...tooltip}"
          >
          <v-icon>mdi mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>LogOut <br>{{ this.userlogin }} </span>
    </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app fixed class="footer" v-if="navbarVisible">
      <span class="white--text">&copy; 2024 SINTACT V2</span>
    </v-footer>
  </v-app>
</template>

<script>
// import '@mdi/font/css/materialdesignicons.min.css'
import { appendQueryString } from "@/services/checkApiParams.js";
// import { link } from "@/config/link";
// import { getApiUrl } from '@/config/configService.js';
import axios from 'axios';
// import { settings } from "@/config/settings";
export default {
  data() {
    return {
      accessToken : "",
      userlogin : '',
      id : this.id,
      navbarVisible: true,
      drawer: false,
      title: 'SINTACT V2',
      items : [],
      home : [],
      timer: 36*60, // 30 menit dalam detik
      timerInterval: null,
     
    };
  },
  created() {
    this.user_id = localStorage.getItem('id');
    const token = localStorage.getItem('token');
      if (token) {
        this.accessToken = token;
        // console.log('Access Token:', this.accessToken);
      } else {
        console.error('Token not found.');
      }
      this.leftMenu();
    // console.log("cek iddd",this.user_id)
  },
  methods: {
    handleItemClick(item) {
      if (!item.sub_menu  || item.sub_menu.length === 0) {
        this.navigateAndClose(item);
      } else {
        item.submenuOpen = !item.submenuOpen;
        // Close other submenus
        this.items.forEach((i) => {
          if (i !== item) {
            i.submenuOpen = false;
            i.sub_menu.forEach((subItem) => {
              subItem.submenuOpen = false;
            });
          }
        });
      }
    },
    handleSubItemClick(subItem) {
      if (!subItem.submenu  || subItem.submenu.length === 0) {
        this.navigateAndClose(subItem);
      } else {
        subItem.submenuOpen = !subItem.submenuOpen;
        // Close other submenus
        if (this.items && this.items.sub_menu) {
          const subItem1 = this.items.sub_menu.find((submenu) => submenu.submenuOpen === true);
          if (subItem1) {
            this.items.sub_menu.forEach((i) => {
              if (i !== subItem) {
                i.submenuOpen = false;
                if (i.submenu) {
                  i.submenu.forEach((q) => {
                    q.submenuOpen = false;
                  });
                }
              }
            });
          }
        }
      }
    },
    toggleNavbar() {
      this.navbarVisible = !this.navbarVisible;
    },
    toggleSubmenu(item) {
      item.submenuOpen = !item.submenuOpen;
      // console.log(item.submenuOpen)
    },
    navigate(item) {
      this.$router.push(item.route);
    },
    navigateAndClose(item) {
      this.navigate(item);
      this.closeAllSubmenus();
      this.drawer = false;
    },
    closeAllSubmenus() {
      this.items.forEach((item) => {
        if (item.submenu) {
          item.submenuOpen = false;
          item.submenu.forEach((subItem) => {
            if (subItem.submenu) {
              subItem.submenuOpen = false;
            }
          });
        }
      });
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
      localStorage.removeItem('timer');
      this.timer= 0;
      this.saveTimerToLocalStorage();
      localStorage.removeItem('justLoggedIn2');
    },
    async leftMenu() {
      try {

        // const id_user = this.id;
        // console.log("cek Id user", id_user)

        // const response = await link.get(`/user/left-menu&id_user=${this.id}`);
        const apiUrl = process.env.VUE_APP_API_URL;
        const baseUrl = `${apiUrl}/user/left-menu`;
        const params = { id_user : this.user_id};
        const newUrl = appendQueryString(baseUrl, params);
        
        const response = await axios.get(newUrl,{
          headers : {
            Authorization : `Bearer ${this.accessToken}`
          }
        });
       
        this.items = this.convertData(response.data.data);
        this.home = this.convertData(response.data.data);
        this.items.splice(0,1);
        // console.log("cek item",response)
      } catch (error) {
        // console.error(error);
        if (error.response && error.response.status === 401) {
          console.error("Token expired or invalid. Logging out...");
          this.logout();
        }
      }
    },
    convertData(data) {
      return data.map(item => ({
        nama: item.nama,
        icon: item.icon,
        route: item.action,
        submenuOpen: false,
        sub_menu: item.sub_menu ? item.sub_menu.map(subItem => ({
          text: subItem.nama,
          // icon: subItem.icon,
          route: subItem.action,
          submenuOpen: false,
          submenu: subItem.sub_menu ? subItem.sub_menu.map(subSubItem => ({
            text: subSubItem.nama,
            icon: subSubItem.icon,
            route: subSubItem.action,
            submenuOpen: false
          })) : []
        })) : []
      }));
    },
    loadTimerFromLocalStorage() {
      const storedTimer = localStorage.getItem('timer');
      if (storedTimer) {
        this.timer = parseInt(storedTimer);
      }
    },
    saveTimerToLocalStorage() {
      localStorage.setItem('timer', this.timer.toString());
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timer -= 1;
        this.saveTimerToLocalStorage();
        if (this.timer <= 0) {
          clearInterval(this.timerInterval);
          localStorage.removeItem('justLoggedIn2');
          localStorage.removeItem('token');
          window.location.reload();
          this.timer=36*60;
          this.saveTimerToLocalStorage();
          console.log('Timer habis!');
        }
      }, 1000); // 1 detik
    },
    formatTimer() {
      const minutes = Math.floor(this.timer / 60);
      const seconds = this.timer % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
   this.leftMenu();
   this.userlogin = localStorage.getItem('username');
   this.id = localStorage.getItem('id');
   this.loadTimerFromLocalStorage();
   const justLoggedIn2 = localStorage.getItem('justLoggedIn2');
    if (justLoggedIn2) {
      this.startTimer();
    }else{
      localStorage.removeItem('justLoggedIn2');
    }
  //  console.log("cek id",this.id)
  }
};
</script>


<style scoped>
.navbar {
  background-color: #424a5d;
  color: #ffffff; 
  
}
.submenu-group {
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 6px;
}
.submenu-group2 {
  margin-bottom: 5px;
  margin-left: 20px; 
}


.submenu-open .v-list-item-action {
  transform: rotate(90deg);

}
.v-list-item__title,
.v-list-item-action__icon,
.v-list-item-icon {
  color: #ffffff !important;
  font-size: 12px;
  
}
.v-list-item-icon .v-icon {
  font-size: 8px !important; 

}
.v-list-item__title a {
  color: #ffffff !important;
  text-decoration: none;
  font-size: 12px;
  margin-top: 5px;
  /* padding: 5px 10px; */
}
.v-toolbar-title {
  background-color: #ffd777 !important;
}
.white--text {
  font-weight: 900;
}
.v-list-item:hover,
.v-list-item:hover .v-list-item__title a {
  background-color: #68dff0;
  color: #ffffff !important;
}
.v-list-item:hover .v-list-item-action__icon {
  color: #ffffff !important;
}
.v-list-title{
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  font-size: 14px;
}
.footer {
  background-color: #68dff0;
  color: #68dff0;
  text-align: center;
  padding: 10px;
}
.white--text {
  font-weight: 900;
}
.hide-footer-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
* {
  font-family: inter-var, sans-serif;
}
.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

</style>
<!-- <style src="@/assets/css/materialdesignicons.min.css"></style>
<style src="@/assets/css/fontgoogleapis.css"></style> -->